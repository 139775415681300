import { lazy, memo, Suspense } from "react";

import { ConfigProvider } from "antd";
import { darkTheme, lightTheme } from "configs/ThemeConfig";
import { resources } from "lang";
import Routes from "routes";
import { useAuth } from "store/auth.store";
import { useTheme } from "store/theme.store";
import useBodyClass from "utils/hooks/useBodyClass";
import Loading from "components/shared-components/Loading";

const AppLayout = lazy(() => import("./AppLayout"));
const AuthLayout = lazy(() => import("./AuthLayout"));

const Layouts = () => {
  const { blankLayout, currentTheme, direction, locale } = useTheme();
  const { token } = useAuth();

  const Layout = token && !blankLayout ? AppLayout : AuthLayout;
  const currentAppLocale = resources[locale];

  useBodyClass(`dir-${direction}`);

  const themeConfig =
    currentTheme === "light" ? { ...lightTheme } : { ...darkTheme };

  return (
    <ConfigProvider
      theme={themeConfig}
      direction={direction}
      locale={currentAppLocale.antd}
    >
      <Suspense fallback={<Loading cover="content" />}>
        <Layout>
          <Routes />
        </Layout>
      </Suspense>
    </ConfigProvider>
  );
};

export default memo(Layouts);
