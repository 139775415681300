import React, { useEffect } from "react";
import { useTheme } from "store/theme.store";

const AppRoute = ({ component: Component, routeKey, ...props }) => {
  const { onBlankLayout, blankLayout } = useTheme();

  useEffect(() => {
    const isBlank = blankLayout ? true : false;
    onBlankLayout(isBlank);
  }, [blankLayout]);

  return <Component {...props} />;
};

export default AppRoute;
