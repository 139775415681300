import fetch from "auth/FetchInterceptor";

const AuthService = {};

AuthService.login = function (data) {
  return fetch({
    url: "/Authentication/Token",
    method: "post",
    mode: "no-cors",
    headers: {
      "public-request": "true",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    data: data,
  });
};

export default AuthService;
