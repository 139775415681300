// import authService from '../services/auth.service';
import { create } from "zustand";
import { persist } from "zustand/middleware";
import {
  AUTH_STORAGE,
  AUTH_TOKEN,
  USER_ID,
  USER_NAME,
} from "../constants/AuthConstant";
import AuthService from "services/AuthService";

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const useAuth = create(
  persist(
    (set, get) => ({
      loading: false,
      message: "",
      showMessage: false,
      userId: localStorage.getItem(USER_ID),
      username: localStorage.getItem(USER_NAME),
      redirect: "/",
      token: localStorage.getItem(AUTH_TOKEN),

      showAuthMessage: (message) => {
        set({ message, showMessage: true, loading: false });
      },
      showLoading: () => {
        set({ loading: true });
      },
      hideAuthMessage: () => {
        set({ message: "", showMessage: false });
      },
      authenticated: (token) => {
        set({ loading: false, redirect: "/app/home", token: token });
      },
      login: async (body) => {
        set({ loading: true, showMessage: false });

        try {
          const { data, status } = await AuthService.login(body);
          if (status !== 200) {
            set({ message: data.detail, showMessage: true });
            return;
          }
          const parsed = parseJwt(data);

          set({ token: data, userId: parsed.nameid, username: parsed.name });
          window.localStorage.setItem(AUTH_TOKEN, data);
          window.localStorage.setItem(USER_ID, parsed.nameid);
          window.localStorage.setItem(USER_NAME, parsed.name);
          window.location.href = "/dashboard2";
        } catch (e) {
          set({ message: e?.response?.data?.message, showMessage: true });
        } finally {
          set({
            loading: false,
          });
        }
      },
      logout: () => {
        set({ token: null, userId: null, username: null });
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(USER_ID);
        localStorage.removeItem(USER_NAME);
        window.location.href = "/auth/login";
      },
    }),
    {
      name: AUTH_STORAGE,
    }
  )
);
