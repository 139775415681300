import { notification } from "antd";
import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { AUTH_TOKEN } from "constants/AuthConstant";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

const ENTRY_ROUTE = "/auth/login";
const TOKEN_PAYLOAD_KEY = "authorization";
const PUBLIC_REQUEST_KEY = "public-request";

service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      window.location.href = ENTRY_ROUTE;
    }

    return config;
  },
  (error) => {
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return error?.response;
  }
);

export default service;
