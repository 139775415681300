import { AUTHENTICATED_ENTRY } from "configs/AppConfig";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "store/auth.store";

const PublicRoute = () => {
  const { token } = useAuth();

  return token ? <Navigate to={AUTHENTICATED_ENTRY} /> : <Outlet />;
};

export default PublicRoute;
