import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { BrowserRouter } from "react-router-dom";
import { THEME_CONFIG } from "./configs/AppConfig";
import history from "./history";
import "./lang";
import Layouts from "./layouts";

const themes = {
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  return (
    <div className="App">
      <BrowserRouter history={history}>
        <ThemeSwitcherProvider
          themeMap={themes}
          defaultTheme={THEME_CONFIG.currentTheme}
          insertionPoint="styles-insertion-point"
        >
          <Layouts />
        </ThemeSwitcherProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
